import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { Carousel } from "../components";
// import adv1Img from "../images/advantage1.jpg";
// import adv2Img from "../images/advantage2.jpg";
// import adv3Img from "../images/advantage3.jpg";
// import adv4Img from "../images/advantage4.jpg";
// import recommendsBg from "../images/recommends_bg.svg";
import { observer } from 'mobx-react-lite';
import {Colors} from 'constants';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

/*
const Container = styled.div`
	label {
		margin-bottom: 20px;
		
		div {
			font-size: 16px;
			@media (max-width: 1279px) { height: 40px; }
		}

		@media (min-width: 980px) {
			//font-size: 18px;
			//line-height: 21px;
		}
		
		input {
			margin-top: 10px;
			background-color: white;
			height: 40px;
			padding: 0 10px;

			&::placeholder { color: rgba(3, 71, 129, 0.7); }
		}
		
		&.special {
			@media (max-width: 539px) {
				height: 85px;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
`;

 */

const SendButton = styled.a`
	height: 30px;
	background: linear-gradient(180deg, #22AFFE 0%, #0270CB 100%);
	font-size: 14px;
	text-transform: uppercase;
	border-radius: 5px;
	
	&:hover {
		background: linear-gradient(180deg, #0270CB 0%, #0270CB 100%);
	}
	
	@media (min-width: 980px) {
		height: 40px;
		font-size: 16px;
	}
	
	&[disabled] {
		background: #D1D1D1;
	} 
`;

/*
const RecContainer = styled.div`
	@media (min-width: 980px) {
		background: url(${recommendsBg}) no-repeat right center;
	}
`;
 */

/*
const Req = styled.div`
	color: #AA1F00 !important;
	font-weight: bold !important;
	display: inline !important;
`;
 */

const AddHotelForm = styled.div`
	label {
		display: flex;
		flex-direction: column;	
	
		margin-bottom: 20px;
		
		&.no-margin { margin-bottom: 0; }
		
		div {
			font-weight: 500;
			font-size: 16px;
			line-height: 21px;
			color: ${Colors.blue};
			margin-bottom: 5px;
			&.no-margin { margin-bottom: 0; }
			
			@media (min-width: 980px) {
				font-size: 18px;
				line-height: 23px;
			}
			
			&.grey {
				color: #333333;
				font-weight: normal;
				height: 40px;
				display: flex;
				flex-direction: row;
				align-items: center;
				font-family: 'PT Sans', sans-serif !important;
				
				font-size: 14px;
				line-height: 18px;
				@media (min-width: 980px) {
					font-size: 16px;
					line-height: 20px;
				}
			}
			
			&.row {
				&:nth-child(n + 2) { margin-top: 10px; }
				margin-bottom: 0;
								
				label {
					flex-direction: row;
					font-family: 'PT Sans', sans-serif !important;
					font-weight: normal;
					margin-bottom: 0;
					
					font-size: 14px;
					line-height: 18px;
					@media (min-width: 980px) {
						font-size: 16px;
						line-height: 20px;
					}
				}
			}
		}

		input[type="text"] {
			outline: none !important;
			border: 1px solid ${Colors.blue};
			color: ${Colors.blue};
			padding: 0 10px;
			height: 40px;
			width: 100%;
			font-family: 'PT Sans', sans-serif !important;

			font-size: 14px;
			line-height: 18px;
			@media (min-width: 980px) {
				font-size: 16px;
				line-height: 21px;
			}
		}

		&.col-4 {
			input {
				width: 80px;
				@media (min-width: 540px) { width: 140px; }
				@media (min-width: 1280px) { width: 160px; }
				@media (min-width: 1600px) { width: 200px; }
			}
		}
	}
`;

const AddHotel = observer(({mainStore = {}}) => {
	const { t } = useTranslation();
	const [ isShow, setIsShow ] = useState(false);
	const [ errors, setErrors ] = useState([]);

	useEffect(() => window.scrollTo(0, 0), []);

	const channels = [
		{Code: 98, Name: 'Ayshotel'},
		{Code: 3, Name: 'Bnovo'},
		{Code: 6, Name: 'Bookinglite'},
		{Code: 5, Name: `CM TravelClick (${t('двусторонний')})`},
		{Code: 9, Name: `CM TravelClick (${t('односторонний')})`},
		{Code: 10, Name: 'CM TravelLine'},
		{Code: 13, Name: 'CM WuBook'},
		{Code: 14, Name: 'Frontdesk24'},
		{Code: 15, Name: 'Hotelinstinct'},
		{Code: 16, Name: 'IHG(ChinaOnline)'},
		{Code: 17, Name: 'Kontur'},
		{Code: 117, Name: 'Lukevium'},
		{Code: 18, Name: 'Marriott(ChinaOnline)'},
		{Code: 92, Name: 'New Logic'},
		{Code: 12, Name: 'OtelMS'},
		{Code: 94, Name: 'SK Hotel Management'},
		{Code: 102, Name: 'UHotels'},
		{Code: 101, Name: 'Бронируй Онлайн'},
		{Code: 93, Name: 'Эделинк'},
	];

	const [ inputs, setInputs ] = useState({
		name: '',
		city: '',
		site: '',
		roomsTotal: '',
		roomsIndividual: '',
		entrance: null,
		desk24: null,
		channel: t('Отсутствует'),
		simpleNalog: null,
		isLive: null,
		nds: null,
		inn: '',
		contactFio: '',
		contactEmail: '',
		contactPhone: '',
		agree: false,
	});

	const handleChangeInput = e => {
		const name = e.target.getAttribute('data-input');
		const value = name === 'inn'
			? e.target.value.trim().replaceAll(/[^0-9]+/g, '')
			: e.target.value;
		setInputs({...inputs, [name]: value});
	};

	const handleChangeCheckbox = e => {
		const name = e.target.getAttribute('data-input');
		setInputs({...inputs, [name]: !inputs[name]});
	};

	const handleChangeNds = e => {
		setInputs({...inputs, 'nds': e.target.value});
	};

	const handleSubmit = e => {
		e.preventDefault();

		let errors = [];

		inputs.name.trim().length < 2 && errors.push(t('Заполните название отеля'));
		inputs.city.trim().length < 2 && errors.push(t('Заполните город'));
		inputs.site.trim().length < 2 && errors.push(t('Заполните официальный сайт отеля'));
		inputs.roomsTotal.trim().length < 1 && errors.push(t('Заполните общее количество номеров'));
		inputs.roomsIndividual.trim().length < 1 && errors.push(t('Заполните количество номеров с индивидуальными удобствами'));
		inputs.entrance === null && errors.push(t('Есть ли у отеля отдельный вход с улицы с вывеской?'));
		inputs.desk24 === null && errors.push(t('Есть ли у отеля круглосуточно работающая стойка регистрации?'));
		inputs.simpleNalog === null && errors.push(t('Выберите одну из применяемых систем налогообложения'));
		inputs.isLive === null && errors.push(t('Регистрация согласно ЕГРП в жилом/нежилом фонде?'));
		inputs.nds === null && errors.push(t('Ставка НДС размещения'));
		// inputs.codes.trim().length < 1 && errors.push(t('Введите хотя бы один код ОКВЭД на услуги'));
		inputs.inn.trim().length < 10 && errors.push(t('Не указан ИНН'));
		inputs.contactFio.trim().length < 2 && errors.push(t('Не указано ФИО контактного лица'));
		/\S+@\S+\.\S+/.test(inputs.contactEmail.trim()) === false && errors.push(t('Не указан Email контактного лица'));
		inputs.contactPhone.trim().length < 1 && errors.push(t('Не указан телефон контактного лица'));
		inputs.agree === false && errors.push(t('Нет согласия на обработку персональных данных'));

		errors.length > 0 && setErrors(errors);

		errors.length === 0 && mainStore.mail(inputs, () => setIsShow(true));
	};

	const handleClose = () => setIsShow(false);

	const handleCloseErrors = () => setErrors([]);

	return (
		<>
			<div className={isShow ? 'modal success show' : 'modal success'}>
				<div className='close' onClick={handleClose} />
				<div className='col-1 flex column align-center bg-green padding-40 color-white'>
					<p className='font-34 margin-bottom-40 text-center'>{t('Ваша заявку успешно отправлена')}</p>
					<div className='button-ok font-16 col-3-2 flex align-center justify-center' onClick={handleClose}>{t('Закрыть')}</div>
				</div>
			</div>

			<div className={classNames('modal', 'error', {'show': errors.length > 0})}>
				<div className='close' onClick={handleCloseErrors} />
				<div className='col-1 flex column align-center bg-red padding-40 color-white'>
					<p className='font-34 margin-bottom-20 text-center'>{t('Заполнены не все данные')}:</p>
					{errors.map((i, key) => <p key={key} className='font-18 text-center'> - {i}</p>)}
					<div className='margin-bottom-40' />
					<div className='button-ok font-16 col-3-2 flex align-center justify-center' onClick={handleCloseErrors}>{t('Закрыть')}</div>
				</div>
			</div>

			<div className='text-block pt-sans padding-80 font-20 color-grey'>
				{t('Для того, чтобы Ваш отель стал доступным для приобретения в нашей системе бронирования, необходимо предварительно заключить договор. Заполните мини-анкету ниже, и сотрудник департамента контрактинга свяжется с Вами для обсуждения всех деталей договора.')}
			</div>

			<div className='divider col-1 margin-bottom-40' />

			<div className='col-1 margin-bottom-80'>
				<p className='color-blue font-42 text-center margin-bottom-40'>{t('Для подключения к системе отправьте запрос')}</p>

				<AddHotelForm className='flex row wrap justify-between'>
					<div className='col-2'>
						<label className='flex'>
							<div>{t('Название отеля')}</div>
							<input type='text' value={inputs.name} data-input='name' onChange={handleChangeInput} />
						</label>

						<label>
							<div>{t('Город')}</div>
							<input type='text' value={inputs.city} data-input='city' onChange={handleChangeInput} />
						</label>

						<label>
							<div>{t('Официальный сайт отеля')}</div>
							<input type='text' value={inputs.site} data-input='site' onChange={handleChangeInput} />
						</label>

						<label>
							<div className='no-margin'>{t('Количество номеров')}</div>

							<label className='flex row justify-between no-margin'>
								<label className='col-4 no-margin'>
									<div className='grey grow'>{t('Общее')}:</div>
									<input type='text' value={inputs.roomsTotal} data-input='roomsTotal' onChange={handleChangeInput} />
								</label>

								<label className='flex col-4 no-margin'>
									<div className='grey grow'>{t('С индивидуальными удобствами')}:</div>
									<input type='text' value={inputs.roomsIndividual} data-input='roomsIndividual' onChange={handleChangeInput} />
								</label>
							</label>
						</label>

						<label>
							<div className='no-margin'>{t('Отдельный вход в отель с улицы с вывеской')}</div>

							<div className='row'>
								<input type="radio" name='separate_entrance' id='separate_entrance_yes' checked={inputs.entrance === true} data-input='entrance' onChange={handleChangeCheckbox} />
								<label htmlFor='separate_entrance_yes'>{t('Да')}</label>
							</div>

							<div className='row'>
								<input type="radio" name='separate_entrance' id='separate_entrance_no' checked={inputs.entrance === false} data-input='entrance' onChange={handleChangeCheckbox} />
								<label htmlFor='separate_entrance_no'>{t('Нет')}</label>
							</div>
						</label>


						<label>
							<div className='no-margin'>{t('Круглосуточно работающая стойка регистрации')}</div>

							<div className="row">
								<input type="radio" name='desk24' id='desk24_yes' checked={inputs.desk24 === true} data-input='desk24' onChange={handleChangeCheckbox} />
								<label htmlFor='desk24_yes'>{t('Да')}</label>
							</div>

							<div className="row">
								<input type="radio" name='desk24' id='desk24_no' checked={inputs.desk24 === false} data-input='desk24' onChange={handleChangeCheckbox} />
								<label htmlFor='desk24_no'>{t('Нет')}</label>
							</div>
						</label>

						<label>
							<div>{t('Менеджер каналов')}</div>

							<select value={inputs.channel} data-input='channel' onChange={handleChangeInput} style={{marginBottom: '0'}}>
								<option value='Отсутствует'>{t('Отсутствует')}</option>
								{channels.map(i => <option key={i.Code} value={i.Name.trim()}>{i.Name.trim()}</option>)}
							</select>
						</label>
					</div>

					<div className='col-2'>
						<label>
							<div className='no-margin'>{t('Применяемая система налогообложения')}</div>

							<div className="row">
								<input type="radio" name='tax' id='tax_simple' checked={inputs.simpleNalog === true} data-input='simpleNalog' onChange={handleChangeCheckbox} />
								<label htmlFor='tax_simple'>{t('Упрощенная система налогообложения — УСНО')}</label>
							</div>

							<div className="row">
								<input type="radio" name='tax' id='tax_base' checked={inputs.simpleNalog === false} data-input='simpleNalog' onChange={handleChangeCheckbox} />
								<label htmlFor='tax_base'>{t('Основная система налогообложения — ОСНО')}</label>
							</div>
						</label>

						<label>
							<div className='no-margin'>{t('Ставка НДС размещения')}</div>

							<div className="row">
								<input type="radio" name='nds' id='nds20' value='20' checked={Number(inputs.nds) === 20} data-input='nds' onChange={handleChangeNds} />
								<label htmlFor='nds20'>{t('20%')}</label>
							</div>
							<div className="row">
								<input type="radio" name='nds' id='nds10' value='10' checked={Number(inputs.nds) === 10} data-input='nds' onChange={handleChangeNds} />
								<label htmlFor='nds10'>{t('10%')}</label>
							</div>
							<div className="row">
								<input type="radio" name='nds' id='nds7' value='7' checked={Number(inputs.nds) === 7} data-input='nds' onChange={handleChangeNds} />
								<label htmlFor='nds7'>{t('7%')}</label>
							</div>
							<div className="row">
								<input type="radio" name='nds' id='nds5' value='5' checked={Number(inputs.nds) === 5} data-input='nds' onChange={handleChangeNds} />
								<label htmlFor='nds5'>{t('5%')}</label>
							</div>
							<div className="row">
								<input type="radio" name='nds' id='nds0' value='-1' checked={Number(inputs.nds) === -1} data-input='nds' onChange={handleChangeNds} />
								<label htmlFor='nds0'>{t('без НДС')}</label>
							</div>

						</label>

						<label>
							<div className='no-margin'>{t('Регистрация согласно ЕГРП')}</div>

							<div className="row">
								<input type="radio" name='fond' id='fond_inlive' checked={inputs.isLive === false} data-input='isLive' onChange={handleChangeCheckbox} />
								<label htmlFor='fond_inlive'>{t('В нежилом фонде')}</label>
							</div>

							<div className="row">
								<input type="radio" name='fond' id='fond_live' checked={inputs.isLive === true} data-input='isLive' onChange={handleChangeCheckbox} />
								<label htmlFor='fond_live'>{t('В жилом фонде')}</label>
							</div>
						</label>

						<label>
							<div>{t('ИНН')}</div>
							<input type="text" maxLength='12' data-input='inn' value={inputs.inn} onChange={handleChangeInput} />
						</label>

						{/*
						<label>
							<div>{t('Коды ОКВЭД на услуги, указанные в юридических и бухгалтерских документах')}</div>

							<textarea value={inputs.codes} data-input='codes' onChange={handleChangeInput} style={{marginBottom: 0}} />
						</label>
						*/}

						<label>
							<div>{t('Контактное лицо (ФИО)')}</div>
							<input type='text' value={inputs.contactFio} data-input='contactFio' onChange={handleChangeInput} />
						</label>

						<label>
							<div>{t('Email контактного лица')}</div>
							<input type='text' value={inputs.contactEmail} data-input='contactEmail' onChange={handleChangeInput} />
						</label>

						<label>
							<div>{t('Телефон контактного лица')}</div>
							<input type='text' value={inputs.contactPhone} data-input='contactPhone' onChange={handleChangeInput} />
						</label>

						<br />

						<label>
							<div className="row">
								<input type="checkbox" name='agree' id='agree' checked={inputs.agree} data-input='agree' onChange={handleChangeCheckbox} />
								<label htmlFor='agree'>{t('Я даю согласие на обработку своих персональных данных')}</label>
							</div>
						</label>

						<SendButton href='/' className='width-picto color-white flex row align-center justify-center' onClick={handleSubmit}>{t('Отправить')}</SendButton>
					</div>
				</AddHotelForm>
			</div>

			<div className='block-fullwidth bg-blue padding-80'>
				<p className='col-1 text-center color-white font-42 font-bold'>
					{t('Для подключения к системе отправьте запрос на')} <a href="mailto:dogovor@acase.ru" className='text-underline'>dogovor@acase.ru</a>
				</p>
			</div>

			<div className='block-fullwidth bg-blue'>
				<div className='divider col-1 bg-white' />
			</div>
		</>
	);
});

export default AddHotel;

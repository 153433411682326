import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Docs = styled.div`
	position: relative;
	
	a {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		
		width: 100%;
		height: 100px;
		padding: 0 20px;
	}
`;

const Legaldocs = () => {
	const { t } = useTranslation();

	useEffect(() => window.scrollTo(0, 0), []);

	return (
		<div className='text-block pt-sans padding-80 font-20'>
			<Docs className='text-block flex row wrap justify-between margin-bottom-40'>
				<a href="https://images.acase.ru/sjas/doc/Сводная ведомость2023АКАДЕМ.pdf" target="_blank" className='border radius flex row align-center font-16 color-blue hover margin-bottom-40' rel="noreferrer">
					<div>{t('СПЕЦИАЛЬНАЯ ОЦЕНКА УСЛОВИЙ ТРУДА')}</div>
					<div> {'>'} </div>
				</a>

			</Docs>
		</div>



	);
};

export default Legaldocs;